import { Box, Flex, LightMode, ListItem, Text, UnorderedList, Icon } from '@chakra-ui/react'
import React from 'react'
import { FiLock } from 'react-icons/fi'

interface PricingCardProps {
  color: string
  button: React.ReactElement
  data: {
    name: string
    description: string
    monthly: number
    yearly: string
    one_time: string
    features: string[]
    duration: 'monthly' | 'yearly' | 'one_time'  // Add one-time option
    tag?: string  // Add billing badge text as a prop
    locked?: boolean  // Add locked property
    firstMonthPrice?: number  // Optional first month price
  }
}

const BillingBadge = ({ text }: { text: string }) => (
  <Box
    rounded="full"
    fontSize="sm"
    bg="white"
    color="gray.900"
    px="3"
    py="1"
    pos="absolute"
    top="4"
    right="4"
    fontWeight="bold"
  >
    {text}
  </Box>
)

export const PricingCard = (props: PricingCardProps) => {
  const { button, data, ...rest } = props
  const { name, description, monthly, yearly, one_time, duration, features, tag, locked, firstMonthPrice } = data
  const isFree = monthly === 0

  // Define the price mapping for each duration
  const priceMap: Record<typeof duration, string> = {
    monthly: firstMonthPrice ? `$${firstMonthPrice}` : `$${monthly}`,
    yearly: `$${yearly}`,
    one_time: `$${one_time}`,
  }

  const periodTextMap: Record<typeof duration, string> = {
    monthly: firstMonthPrice ? 'first month' : 'per month',
    yearly: 'per year',
    one_time: 'one-time payment',
  }

  return (
    <Flex
      direction="column"
      px="6"
      py="8"
      rounded="lg"
      pos="relative"
      maxW="2xl"
      mx="auto"
      overflow="hidden"
      {...rest}
    >
      {locked && (
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="rgba(0, 0, 0, 0.8)"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          opacity="1"
          transition="opacity 0.3s ease"
          _hover={{ opacity: 0 }}
          zIndex="5"
        >
          <Icon as={FiLock} boxSize={12} color="white" />
          <Text fontSize="md" lineHeight="1" color="white" fontWeight="bold" textAlign="center" mt="3">
            Selected languages exceed plan limit
          </Text>
        </Box>
      )}

      <Box flex="1">
        {tag && <BillingBadge text={tag} />}

        <Text fontSize="2xl" lineHeight="1" fontWeight="bold">
          {name}
        </Text>

        <Flex align="center" fontWeight="extrabold" mt="4" mb="3">
          <Text fontSize={{ base: '6xl', xl: '7xl' }} lineHeight="1" flexShrink={0}>
            {priceMap[duration]}
          </Text>
          {!isFree && (
            <>
              <Box mx="4">
                <Box w="2px" h="12" bg={props.color} opacity={0.5} transform="rotate(15deg)" />
              </Box>
              <Text fontSize="xl" lineHeight="1.2">
                {periodTextMap[duration]}
              </Text>
            </>
          )}
        </Flex>

        {duration === 'monthly' && firstMonthPrice && (
          <Text fontSize={{ base: '1xl', xl: '1xl' }} fontWeight="bold" lineHeight="1" flexShrink={0}>
            then ${monthly} per month
          </Text>
        )}

        <Box mt="6">
          <Text fontSize="xl" fontWeight="semibold" mb="6">
            {description}
          </Text>
          <UnorderedList spacing="3">
            {features.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Box>

      <Box mt="10" zIndex="2">
        <LightMode>{button}</LightMode>
      </Box>
    </Flex>
  )
}
