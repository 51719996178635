import React from 'react'
import {Box, Flex, Heading, Text, useColorModeValue, Link} from '@chakra-ui/react'
import PricingBlock from '../../components/PricingBlock'
import { Link as RouterLink } from 'react-router-dom'

const PricingInfo = () => {
  return (
    <>
      <Box as="section" py="10">
        <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ base: '6', md: '8' }}>
          {/* Header Section */}
          <Flex textAlign="center" align="center" direction="column">
            <Heading
              size="2xl"
              lineHeight="normal"
              color={useColorModeValue('blue.600', 'blue.400')}
              letterSpacing="tight"
              fontWeight="extrabold"
            >
              Ready to Get Started?
            </Heading>
            <Text
              fontSize="l"
              mt="4"
              maxW="2xl"
              mx={{ base: 'auto', lg: 'unset' }}
            >
              Choose the best plan for you, whether you are just starting out or scaling up. Our
              pricing plans are flexible and transparent, tailored to meet your needs.
            </Text>
          </Flex>

          {/* Pricing Block */}
          <Box mt="10">
            <PricingBlock informationalMode={true} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default PricingInfo
