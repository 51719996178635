import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text, Spinner, useColorModeValue } from '@chakra-ui/react';
import { FaCheck, FaTimes, FaExternalLinkAlt } from 'react-icons/fa';
import { Header } from "../../components/Header";
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from "react-router-dom";

const EditorInstall = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null); // 'website', 'editor', or 'error'
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [editorLink, setEditorLink] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch the installation data
  useEffect(() => {
    const installTranslator = async () => {
      try {
        const response = await axiosInstance.post('/weblang/api/installations');
        setWebsiteUrl(response.data.website_url || '');
        setEditorLink(response.data.editor_link || '');

        if (response.data.website_url) {
          setStatus('website');
        } else if (response.data.editor_link) {
          setStatus('editor');
        } else {
          setStatus('error');
        }
      } catch (error) {
        setStatus('error');
      } finally {
        setLoading(false);
      }
    };
    installTranslator();
  }, []);

  const checkColor = useColorModeValue('blue.600', 'blue.400');
  const crossColor = useColorModeValue('red.400', 'red.400');
  const textColor = useColorModeValue('gray.600', 'gray.400');

  if (loading) {
    return (
      <Box textAlign="center" py="10">
        <Spinner size="xl" />
        <Text mt="4">Installing Translator...</Text>
      </Box>
    );
  }

  return (
    <Box as="section" py="10">
      <Flex maxW={{ base: '600px' }} mx="auto" px={{ base: '6', md: '8' }} direction="column" align="center" textAlign="center">

        {status === 'website' && (
          <>
            <FaCheck size="100px" color="#2b6cb0" />
            <Heading mt="6" color={checkColor} fontSize="4xl">
              Translator Installed
            </Heading>
            <Text mt="4" fontSize="lg" color={textColor}>
              <b>Visit your website</b>, your <b>language control is on the right side</b> of the screen. If you need to customize it or add more languages, you can do it on the next page.
            </Text>
            <Button
              mt="8"
              py="6"
              colorScheme="blue"
              onClick={() => window.open(websiteUrl, '_blank')}
              rightIcon={<FaExternalLinkAlt />}
              width="400px"
            >
              Open Your Website
            </Button>
            <Button
              mt="4"
              py="6"
              variant="outline"
              colorScheme="blue"
              onClick={() => navigate('/weblang/customise_controls')}
              width="400px"
            >
              Customize Controls
            </Button>
          </>
        )}

        {status === 'editor' && (
          <>
            <FaCheck size="100px" color="#2b6cb0" />
            <Heading mt="6" color={checkColor} fontSize="4xl">
              Publish Your Website
            </Heading>
            <Text mt="4" fontSize="lg" color={textColor}>
              <b>Publish your website in the Wix Editor</b>. Your language control is on the right side of the screen. Remember that <b>they are not visible in editor/preview mode</b> — you can only see them on the live site.
            </Text>
            <Button
              mt="8"
              py="6"
              colorScheme="blue"
              onClick={() => window.open(editorLink, '_blank')}
              rightIcon={<FaExternalLinkAlt />}
              width="400px"
            >
              Go to Editor
            </Button>
            <Button
              mt="4"
              py="6"
              variant="outline"
              colorScheme="blue"
              onClick={() => navigate('/weblang/customise_controls')}
              width="400px"
            >
              Customize Controls
            </Button>
          </>
        )}

        {status === 'error' && (
          <>
            <FaTimes size="100px" color={crossColor} />
            <Heading mt="6" color={crossColor} fontSize="4xl">
              Installation Failed
            </Heading>
            <Text mt="4" fontSize="lg" color={textColor}>
              We encountered an unexpected issue while setting up the translation tool on your website.
              Please contact our support team at <b>andrew@rubyroid.tech</b> for assistance. We're here to help get you up and running smoothly!
            </Text>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default EditorInstall;
